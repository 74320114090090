import axios from "axios";
import { baseUrl } from "../utils/constant";

export const getRemittanceReportData = (remittanceNumber, lang) => {
	return axios.get(
		`${baseUrl}/receipt/remittance/transaction?transctionNumber=${remittanceNumber}&lang=${lang}`
	);
};

export const getImportRemittanceReportData = (remittanceNumber, lang) => {
	return axios.get(
		`${baseUrl}/receipt/import-remittance/transaction?transctionNumber=${remittanceNumber}&lang=${lang}`
	);
};

export const getRefundRemittanceReportData = (remittanceNumber, lang) => {
	debugger;
	return axios.get(
		`${baseUrl}/receipt/remittance/Refundtransaction?transctionNumber=${remittanceNumber}&lang=${lang}`
	);
};

export const getExchangeTransaction = (transactionNumber, lang) => {
	return axios.get(
		`${baseUrl}/receipt/exchange/transaction?transctionNumber=${transactionNumber}&lang=${lang}`
	);
};
export const getBulkExchangeTransaction = (transactionNumber, lang) => {
	return axios.get(
		`${baseUrl}/receipt/exchange/transaction?transctionNumber=${transactionNumber}&lang=${lang}`
	);
};
export const getAgentExchangeRequest = (requestNumber, lang) => {
	return axios.get(
		`${baseUrl}/receipt/exchange/request?requestNumber=${requestNumber}&lang=${lang}`
	);
};
export const getAuctionExchangeRequest = (requestNumber, lang) => {
	return axios.get(
		`${baseUrl}/receipt/auction/request?requestNumber=${requestNumber}&lang=${lang}`
	);
};
export const getCashTransaction = (transactionNumber, lang) => {
	return axios.get(
		`${baseUrl}/receipt/cash/transaction?transctionNumber=${transactionNumber}&lang=${lang}`
	);
};

export const getInternalCashMoveData = (cashmoveid, lang) => {
	return axios.get(
		`${baseUrl}/receipt/cashmove/transaction?transctionNumber=${cashmoveid}&lang=${lang}`
	);
};
export const getInternalCashReconciliationReportData = (cashmoveid, lang) => {
	return axios.get(
		`${baseUrl}/receipt/casherconciliation?transctionNumber=${cashmoveid}&lang=${lang}`
	);
};
export const getTransactionReversalData = (cashmoveid, lang) => {
	return axios.get(
		`${baseUrl}/receipt/transactionreversal?transctionNumber=${cashmoveid}&lang=${lang}`
	);
};
export const getGiftCardTransaction = (transactionNumber, lang) => {
	return axios.get(
		`${baseUrl}/receipt/giftcard/transaction?transctionNumber=${transactionNumber}&lang=${lang}`
	);
};
export const getBulkSingleTransaction = (transactionNumber, lang) => {
	return axios.get(
		`${baseUrl}/receipt/bulksingle/transaction?transctionNumber=${transactionNumber}&lang=${lang}`
	);
};

// function getHeader() {
// 	const token = getCookie("TOKA");
// 	const header = { params: { TOKA: `${token}` } };

// 	return header;
// }

function getCookie(name) {
	var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
	return v ? v[2] : null;
}
