import React, { Component } from "react";
import {
	Header,
	Signature,
	SenderInfo,
	BenificiaryInfo,
	OtherInfo,
  FinancialInfo,
  BenificiaryFullInfo
} from "./ReceiptComponents";
import "./Remittance.css";

// The main component
export default class ImportRemittanceReceipt extends Component {
  constructor(props) {
    super(props);
    this.isBankCopy = props.isBankCopy;
    this.reportData = props.result;
    this.reportData.isImportRemittanceReceipt = true;
    this.showConsent = props.result.showConsent;
	  this.hideSignature = props.hideSignature;
    document.title = "Import_Remittances_Receipt_" + props.result.transactionNumber;
  }

  render() {
    const consent = this.showConsent ? (
      <p style={{ fontSize: "10px", marginBottom: "0" }}>
        I consent to receive communications related to this transaction
      </p>
    ) : null;

    return (
      <div className="content">
        <table width="100%">
          <tbody>
            <tr>
              <td width="100%" className="p-0 header-space"></td>
            </tr>
          </tbody>
        </table>
        <Header data={this.reportData} isReprint={this.isReprint} />
        <table width="100%">
          <tbody>
            <tr>
              <td width="48%" valign="top">
                <table width="100%" cellPadding="0" cellSpacing="0">
                  <tbody>
                  {this.reportData.isPayReceipt ? ( <BenificiaryFullInfo data={this.reportData.remittanceInfo} /> ) : 
                                                  ( <SenderInfo data={this.reportData.remittanceInfo} /> )}
                  </tbody>
                </table>
              </td>
              <td width="2%" align="center" style={{ position: "relative" }}>
                <p className="devider"></p>
              </td>
              <td width="48%" valign="top">
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="100%" className="p-0">
                        <h3>
                          <span className="w-50">Financial Info</span>
                          <span className="w-50 text-right">المعلومات المالية</span>
                        </h3>
                      </td>
                    </tr>
                    <tr>
                      <td width="100%" className="p-0">
                        <table width="100%" cellPadding="0" cellSpacing="0" className="financial-info">
                          <tbody>
                            <tr>
                              <td align="left" className="valign-middle">
                                <p>{this.reportData.isPayReceipt ? `Pay Amount` : 'Remittance Amount'}:</p>
                              </td>
                              <td align="left" className="valign-middle">
                                {this.reportData.sendCurrencyCode}
                              </td>
                              <td align="right" className="valign-bottom" style={{ fontSize: '11px' }}>
                                <b>{this.reportData.sendAmount}</b>
                              </td>
                              <td align="right" className="valign-bottom">:مبلغ الحوالة</td>
                            </tr>
                            <tr>
                              {this.reportData.isPayReceipt ? null : (
                                <>
                                  <td align="left" className="valign-middle">
                                    <p>Total Fee:</p>
                                  </td>
                                  <td align="left" className="valign-middle">
                                    {this.reportData.feeCurrencyCode}
                                  </td>
                                  <td align="right" className="valign-bottom" style={{ fontSize: '11px' }}>
                                    <b>{this.reportData.feeAmount}</b>
                                  </td>
                                  <td align="right" className="valign-bottom">:الرسوم إجمالي</td>
                                </>
                              )}
                            </tr>
                            {this.reportData.HideGrandTotal && (
                              <>
                                <tr>
                                  <td align="left" class="valign-middle">
                                    <p>Grand-Total:</p>
                                  </td>
                                  <td align="left" class="valign-middle">
                                    {this.reportData.sendCurrencyCode}
                                  </td>
                                  <td align="right" class="valign-bottom" style={{ fontSize: '11px' }}>
                                    <b>{this.reportData.grandTotal}</b>
                                  </td>
                                  <td align="right" class="valign-bottom">:المجموع</td>
                                </tr>
                                <tr>
                                  <td colspan="3" align="right">
                                    <b>{this.reportData.grandTotalInWords}</b>
                                  </td>
                                </tr>
                              </>
                            )}  
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr style={{ height: "5px" }}><td></td></tr>
			      <tr style={{ borderTop: "2px solid #dfdfdf", margin: "10px 0" }}></tr>
          </tbody>
        </table>
        <SignatureSection reportData={this.reportData} hide={this.hideSignature} />
        <p style={{ textAlign: 'right' }}>
          Page 1 / {this.reportData.pageCount}
        </p>
        {consent}
      </div>
    );
  }
}

const SignatureSection = ({ reportData, hide }) => {
  return (
    <table width="100%" height="40">
      <tbody>
        <tr>
          <td width="50%" style={{ verticalAlign: "middle", textAlign: "center", paddingTop: "10px" }}>
            <div style={{ marginBottom: "10px" }}>Customer Signature / توقيع الزبون</div>
            {!hide && (
              <div style={{ height: "125px", width: "300px", border: "3px solid", margin: "3px auto", borderRadius: "3px" }}></div>
            )}
            {!hide && (
              <div style={{ marginTop: "10px" }}>Sign within the box <br></br> I consent to have my signature digitally copied to individual receipts</div>
            )}
            </td>
          <td width="50%" style={{ verticalAlign: "middle", textAlign: "center", paddingTop: "10px" }}>
            <div style={{ marginBottom: "10px" }}>Cashier Signature / توقيع أمين الصندوق</div>
            {!hide && (
              <div style={{ height: "125px", width: "300px", border: "3px solid", margin: "3px auto", borderRadius: "3px" }}></div>
            )}
            {!hide && (
              <div style={{ marginTop: "10px" }}>يتوجب التوقيع ضمن حدود المربع <br></br> أقر وأوافق على نسخ توقيعي الحي من هذا الوصل إلى الوصولات المنفردة للحوالات</div>
            )}        
          </td>
        </tr>
      </tbody>
    </table>
  );
};
