import React, { Component } from 'react';
import PayReceipt from '../components/Remittance/PayReceipt';
import SendReceipt from '../components/Remittance/SendReceipt';
import { getBulkSingleTransaction, getRemittanceReportData } from '../services/ReportService';

export default class BulkSingleReceipt extends Component {
  constructor() {
    super();
    this.language = 'en';
    this.tid = window.location.pathname.split('/')[2];
    this.isReprintData = window.location.search !== '';
    this.state = {
      transactions: [],
      reportData: [],
      loading: false,
      progress: 0,
      currentIndex: 0,
      loadedCount: 0,
      startTime: null, // Track start time for estimation
      estimatedTime: 0, // Estimated time remaining
      estimatedTimes: [] // Store estimated times for averaging
    };
    this.batchSize = 5; // Variable to set the number of transactions loaded at a time
  }

  componentDidMount() {
    this.fetchTransactionIds();
  }

  fetchTransactionIds() {
    getBulkSingleTransaction(this.tid, this.language).then((res) => {
      if (res.data && res.data.length > 0) {
        const transactions = res.data.map(item => ({ transactionNumber: item.transactionNumber, serviceId: item.serviceId }));
        this.setState({ transactions, startTime: Date.now() }, () => this.fetchNextBatch());
      }
    }).catch(() => {
      // Handle errors if needed
    });
  }

  fetchNextBatch() {
    this.setState({ loading: true });

    const { transactions, currentIndex } = this.state;
    const batch = transactions.slice(currentIndex, currentIndex + this.batchSize);

    const fetchPromises = batch.map((transaction) =>
      getRemittanceReportData(transaction.transactionNumber, this.language).then((res) => {
        if (res.data) {
          res.data.serviceId = transaction.serviceId; // Add serviceId to the report data
          this.setState(prevState => {
            const newLoadedCount = prevState.loadedCount + 1;
            const progress = Math.round((newLoadedCount / transactions.length) * 100);

            // Calculate elapsed time and estimate remaining time
            const elapsedTime = (Date.now() - prevState.startTime) / 1000; // in seconds
            const estimatedTotalTime = (elapsedTime / newLoadedCount) * transactions.length; // estimated total time
            const estimatedTime = Math.max(estimatedTotalTime - elapsedTime, 0).toFixed(0); // remaining time
            
            // Store the estimated time in the array
            const estimatedTimes = [...prevState.estimatedTimes, parseFloat(estimatedTime)];
            // Limit the size of the array to the latest 5 estimated times
            if (estimatedTimes.length > 5) estimatedTimes.shift();

            // Calculate the average of the estimated times
            const averageEstimatedTime = (estimatedTimes.reduce((sum, time) => sum + time, 0) / estimatedTimes.length).toFixed(0);

            return {
              reportData: [...prevState.reportData, res.data],
              loadedCount: newLoadedCount,
              progress,
              estimatedTime: averageEstimatedTime,
              estimatedTimes // Update the array with latest estimates
            };
          });
        }
      })
    );

    Promise.all(fetchPromises).then(() => {
      this.setState(prevState => {
        const newCurrentIndex = prevState.currentIndex + this.batchSize;

        return {
          loading: newCurrentIndex < transactions.length,
          currentIndex: newCurrentIndex
        };
      }, () => {
        if (this.state.currentIndex < this.state.transactions.length) {
          this.fetchNextBatch();
        }
      });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { reportData, loading, progress, transactions, loadedCount, estimatedTime } = this.state;

    return (
      <div>
        <div style={{ width: '100%', padding: '10px', position: 'fixed', top: '0', zIndex: '1', display: loading ? 'block' : 'none' }}>
          <div style={{ width: '80%', height: '20px', backgroundColor: '#e0e0df', borderRadius: '10px', overflow: 'hidden', margin: '0 auto' }}>
            {loading && (
              <div style={{ height: '100%', backgroundColor: '#76c7c0', width: `${progress}%`, transition: 'width 0.5s ease-in-out' }}></div>
            )}
          </div>
          {loading && <h2 style={{ textAlign: 'center' }}>{`Loading Receipts... ${progress}% (${loadedCount}/${transactions.length}) - Estimated time remaining: ${estimatedTime}s`}</h2>}
        </div>
        <div style={{ marginTop: loading ? '60px' : '0' }}>
          {reportData.map((data, index) => (
            <div key={index} style={{ pageBreakAfter: 'always' }}>
              {data.serviceId === 'ImportPayRemittances' ? (
                <>
                  <PayReceipt result={data} isBankCopy={false} isReprint={this.isReprintData}/>
                  <PayReceipt result={data} isBankCopy={true} isReprint={this.isReprintData}/>
                </>
              ) : (
                <>
                  <SendReceipt result={data} isBankCopy={false} isReprint={this.isReprintData}/>
                  <SendReceipt result={data} isBankCopy={true} isReprint={this.isReprintData}/>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
